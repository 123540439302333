// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               v3.21.12
// source: subtrace/tunnel/tunnel.proto
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { FileDescriptorProto } from "ts-proto-descriptors";
export const protobufPackage = "subtrace.tunnel";
export var Role;
(function (Role) {
    Role[Role["INSERT"] = 0] = "INSERT";
    Role[Role["SELECT"] = 1] = "SELECT";
    Role[Role["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Role || (Role = {}));
export function roleFromJSON(object) {
    switch (object) {
        case 0:
        case "INSERT":
            return Role.INSERT;
        case 1:
        case "SELECT":
            return Role.SELECT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Role.UNRECOGNIZED;
    }
}
export function roleToJSON(object) {
    switch (object) {
        case Role.INSERT:
            return "INSERT";
        case Role.SELECT:
            return "SELECT";
        case Role.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseCreate() {
    return {};
}
export const Create = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Create.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(_) {
        const message = createBaseCreate();
        return message;
    },
};
function createBaseCreate_Request() {
    return { projectId: undefined, role: 0 };
}
export const Create_Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.projectId !== undefined) {
            writer.uint32(10).string(message.projectId);
        }
        if (message.role !== 0) {
            writer.uint32(16).int32(message.role);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreate_Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.projectId = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.role = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            projectId: isSet(object.projectId) ? globalThis.String(object.projectId) : undefined,
            role: isSet(object.role) ? roleFromJSON(object.role) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.projectId !== undefined) {
            obj.projectId = message.projectId;
        }
        if (message.role !== 0) {
            obj.role = roleToJSON(message.role);
        }
        return obj;
    },
    create(base) {
        return Create_Request.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseCreate_Request();
        message.projectId = (_a = object.projectId) !== null && _a !== void 0 ? _a : undefined;
        message.role = (_b = object.role) !== null && _b !== void 0 ? _b : 0;
        return message;
    },
};
function createBaseCreate_Response() {
    return { error: "", tunnelId: "", endpoint: "" };
}
export const Create_Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.error !== "") {
            writer.uint32(8002).string(message.error);
        }
        if (message.tunnelId !== "") {
            writer.uint32(10).string(message.tunnelId);
        }
        if (message.endpoint !== "") {
            writer.uint32(18).string(message.endpoint);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreate_Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1000:
                    if (tag !== 8002) {
                        break;
                    }
                    message.error = reader.string();
                    continue;
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tunnelId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.endpoint = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            error: isSet(object.error) ? globalThis.String(object.error) : "",
            tunnelId: isSet(object.tunnelId) ? globalThis.String(object.tunnelId) : "",
            endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.error !== "") {
            obj.error = message.error;
        }
        if (message.tunnelId !== "") {
            obj.tunnelId = message.tunnelId;
        }
        if (message.endpoint !== "") {
            obj.endpoint = message.endpoint;
        }
        return obj;
    },
    create(base) {
        return Create_Response.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseCreate_Response();
        message.error = (_a = object.error) !== null && _a !== void 0 ? _a : "";
        message.tunnelId = (_b = object.tunnelId) !== null && _b !== void 0 ? _b : "";
        message.endpoint = (_c = object.endpoint) !== null && _c !== void 0 ? _c : "";
        return message;
    },
};
function createBaseListOpen() {
    return {};
}
export const ListOpen = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListOpen();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return ListOpen.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(_) {
        const message = createBaseListOpen();
        return message;
    },
};
function createBaseListOpen_Request() {
    return { createAfterTime: BigInt("0") };
}
export const ListOpen_Request = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.createAfterTime !== BigInt("0")) {
            if (BigInt.asIntN(64, message.createAfterTime) !== message.createAfterTime) {
                throw new globalThis.Error("value provided for field message.createAfterTime of type int64 too large");
            }
            writer.uint32(8).int64(message.createAfterTime.toString());
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListOpen_Request();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.createAfterTime = longToBigint(reader.int64());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { createAfterTime: isSet(object.createAfterTime) ? BigInt(object.createAfterTime) : BigInt("0") };
    },
    toJSON(message) {
        const obj = {};
        if (message.createAfterTime !== BigInt("0")) {
            obj.createAfterTime = message.createAfterTime.toString();
        }
        return obj;
    },
    create(base) {
        return ListOpen_Request.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseListOpen_Request();
        message.createAfterTime = (_a = object.createAfterTime) !== null && _a !== void 0 ? _a : BigInt("0");
        return message;
    },
};
function createBaseListOpen_Item() {
    return { tunnelId: "", endpoint: "", role: 0 };
}
export const ListOpen_Item = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tunnelId !== "") {
            writer.uint32(10).string(message.tunnelId);
        }
        if (message.endpoint !== "") {
            writer.uint32(18).string(message.endpoint);
        }
        if (message.role !== 0) {
            writer.uint32(24).int32(message.role);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListOpen_Item();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tunnelId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.endpoint = reader.string();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.role = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tunnelId: isSet(object.tunnelId) ? globalThis.String(object.tunnelId) : "",
            endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
            role: isSet(object.role) ? roleFromJSON(object.role) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tunnelId !== "") {
            obj.tunnelId = message.tunnelId;
        }
        if (message.endpoint !== "") {
            obj.endpoint = message.endpoint;
        }
        if (message.role !== 0) {
            obj.role = roleToJSON(message.role);
        }
        return obj;
    },
    create(base) {
        return ListOpen_Item.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseListOpen_Item();
        message.tunnelId = (_a = object.tunnelId) !== null && _a !== void 0 ? _a : "";
        message.endpoint = (_b = object.endpoint) !== null && _b !== void 0 ? _b : "";
        message.role = (_c = object.role) !== null && _c !== void 0 ? _c : 0;
        return message;
    },
};
function createBaseListOpen_Response() {
    return { error: "", tunnels: [] };
}
export const ListOpen_Response = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.error !== "") {
            writer.uint32(8002).string(message.error);
        }
        for (const v of message.tunnels) {
            ListOpen_Item.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseListOpen_Response();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1000:
                    if (tag !== 8002) {
                        break;
                    }
                    message.error = reader.string();
                    continue;
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tunnels.push(ListOpen_Item.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            error: isSet(object.error) ? globalThis.String(object.error) : "",
            tunnels: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.tunnels)
                ? object.tunnels.map((e) => ListOpen_Item.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        var _a;
        const obj = {};
        if (message.error !== "") {
            obj.error = message.error;
        }
        if ((_a = message.tunnels) === null || _a === void 0 ? void 0 : _a.length) {
            obj.tunnels = message.tunnels.map((e) => ListOpen_Item.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return ListOpen_Response.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseListOpen_Response();
        message.error = (_a = object.error) !== null && _a !== void 0 ? _a : "";
        message.tunnels = ((_b = object.tunnels) === null || _b === void 0 ? void 0 : _b.map((e) => ListOpen_Item.fromPartial(e))) || [];
        return message;
    },
};
function createBaseEventField() {
    return { type: 0, tag: 0 };
}
export const EventField = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.tag !== 0) {
            writer.uint32(16).int32(message.tag);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEventField();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.type = reader.int32();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.tag = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            type: isSet(object.type) ? globalThis.Number(object.type) : 0,
            tag: isSet(object.tag) ? globalThis.Number(object.tag) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.type !== 0) {
            obj.type = Math.round(message.type);
        }
        if (message.tag !== 0) {
            obj.tag = Math.round(message.tag);
        }
        return obj;
    },
    create(base) {
        return EventField.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseEventField();
        message.type = (_a = object.type) !== null && _a !== void 0 ? _a : 0;
        message.tag = (_b = object.tag) !== null && _b !== void 0 ? _b : 0;
        return message;
    },
};
function createBaseClientHello() {
    return { eventFields: [] };
}
export const ClientHello = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.eventFields) {
            EventField.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseClientHello();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.eventFields.push(EventField.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventFields: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.eventFields)
                ? object.eventFields.map((e) => EventField.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        var _a;
        const obj = {};
        if ((_a = message.eventFields) === null || _a === void 0 ? void 0 : _a.length) {
            obj.eventFields = message.eventFields.map((e) => EventField.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return ClientHello.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseClientHello();
        message.eventFields = ((_a = object.eventFields) === null || _a === void 0 ? void 0 : _a.map((e) => EventField.fromPartial(e))) || [];
        return message;
    },
};
function createBaseServerHello() {
    return { eventFields: [] };
}
export const ServerHello = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.eventFields) {
            EventField.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseServerHello();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.eventFields.push(EventField.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventFields: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.eventFields)
                ? object.eventFields.map((e) => EventField.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        var _a;
        const obj = {};
        if ((_a = message.eventFields) === null || _a === void 0 ? void 0 : _a.length) {
            obj.eventFields = message.eventFields.map((e) => EventField.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return ServerHello.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a;
        const message = createBaseServerHello();
        message.eventFields = ((_a = object.eventFields) === null || _a === void 0 ? void 0 : _a.map((e) => EventField.fromPartial(e))) || [];
        return message;
    },
};
function createBaseQuery() {
    return { tunnelQueryId: "", sqlStatement: "", data: undefined };
}
export const Query = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tunnelQueryId !== "") {
            writer.uint32(10).string(message.tunnelQueryId);
        }
        if (message.sqlStatement !== "") {
            writer.uint32(18).string(message.sqlStatement);
        }
        if (message.data !== undefined) {
            writer.uint32(26).bytes(message.data);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuery();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tunnelQueryId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.sqlStatement = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.data = reader.bytes();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tunnelQueryId: isSet(object.tunnelQueryId) ? globalThis.String(object.tunnelQueryId) : "",
            sqlStatement: isSet(object.sqlStatement) ? globalThis.String(object.sqlStatement) : "",
            data: isSet(object.data) ? bytesFromBase64(object.data) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tunnelQueryId !== "") {
            obj.tunnelQueryId = message.tunnelQueryId;
        }
        if (message.sqlStatement !== "") {
            obj.sqlStatement = message.sqlStatement;
        }
        if (message.data !== undefined) {
            obj.data = base64FromBytes(message.data);
        }
        return obj;
    },
    create(base) {
        return Query.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseQuery();
        message.tunnelQueryId = (_a = object.tunnelQueryId) !== null && _a !== void 0 ? _a : "";
        message.sqlStatement = (_b = object.sqlStatement) !== null && _b !== void 0 ? _b : "";
        message.data = (_c = object.data) !== null && _c !== void 0 ? _c : undefined;
        return message;
    },
};
function createBaseResult() {
    return { tunnelQueryId: "", tunnelError: "", clickhouseQueryId: "", clickhouseError: "", data: new Uint8Array(0) };
}
export const Result = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.tunnelQueryId !== "") {
            writer.uint32(10).string(message.tunnelQueryId);
        }
        if (message.tunnelError !== "") {
            writer.uint32(18).string(message.tunnelError);
        }
        if (message.clickhouseQueryId !== "") {
            writer.uint32(26).string(message.clickhouseQueryId);
        }
        if (message.clickhouseError !== "") {
            writer.uint32(34).string(message.clickhouseError);
        }
        if (message.data.length !== 0) {
            writer.uint32(42).bytes(message.data);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tunnelQueryId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.tunnelError = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.clickhouseQueryId = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.clickhouseError = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.data = reader.bytes();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tunnelQueryId: isSet(object.tunnelQueryId) ? globalThis.String(object.tunnelQueryId) : "",
            tunnelError: isSet(object.tunnelError) ? globalThis.String(object.tunnelError) : "",
            clickhouseQueryId: isSet(object.clickhouseQueryId) ? globalThis.String(object.clickhouseQueryId) : "",
            clickhouseError: isSet(object.clickhouseError) ? globalThis.String(object.clickhouseError) : "",
            data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tunnelQueryId !== "") {
            obj.tunnelQueryId = message.tunnelQueryId;
        }
        if (message.tunnelError !== "") {
            obj.tunnelError = message.tunnelError;
        }
        if (message.clickhouseQueryId !== "") {
            obj.clickhouseQueryId = message.clickhouseQueryId;
        }
        if (message.clickhouseError !== "") {
            obj.clickhouseError = message.clickhouseError;
        }
        if (message.data.length !== 0) {
            obj.data = base64FromBytes(message.data);
        }
        return obj;
    },
    create(base) {
        return Result.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e;
        const message = createBaseResult();
        message.tunnelQueryId = (_a = object.tunnelQueryId) !== null && _a !== void 0 ? _a : "";
        message.tunnelError = (_b = object.tunnelError) !== null && _b !== void 0 ? _b : "";
        message.clickhouseQueryId = (_c = object.clickhouseQueryId) !== null && _c !== void 0 ? _c : "";
        message.clickhouseError = (_d = object.clickhouseError) !== null && _d !== void 0 ? _d : "";
        message.data = (_e = object.data) !== null && _e !== void 0 ? _e : new Uint8Array(0);
        return message;
    },
};
export const protoMetadata = {
    fileDescriptor: FileDescriptorProto.fromPartial({
        "name": "subtrace/tunnel/tunnel.proto",
        "package": "subtrace.tunnel",
        "dependency": [],
        "publicDependency": [],
        "weakDependency": [],
        "messageType": [{
                "name": "Create",
                "field": [],
                "extension": [],
                "nestedType": [{
                        "name": "Request",
                        "field": [{
                                "name": "project_id",
                                "number": 1,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "projectId",
                                "options": undefined,
                                "proto3Optional": true,
                            }, {
                                "name": "role",
                                "number": 2,
                                "label": 1,
                                "type": 14,
                                "typeName": ".subtrace.tunnel.Role",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "role",
                                "options": undefined,
                                "proto3Optional": false,
                            }],
                        "extension": [],
                        "nestedType": [],
                        "enumType": [],
                        "extensionRange": [],
                        "oneofDecl": [{ "name": "_project_id", "options": undefined }],
                        "options": undefined,
                        "reservedRange": [],
                        "reservedName": [],
                    }, {
                        "name": "Response",
                        "field": [{
                                "name": "error",
                                "number": 1000,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "error",
                                "options": undefined,
                                "proto3Optional": false,
                            }, {
                                "name": "tunnel_id",
                                "number": 1,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "tunnelId",
                                "options": undefined,
                                "proto3Optional": false,
                            }, {
                                "name": "endpoint",
                                "number": 2,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "endpoint",
                                "options": undefined,
                                "proto3Optional": false,
                            }],
                        "extension": [],
                        "nestedType": [],
                        "enumType": [],
                        "extensionRange": [],
                        "oneofDecl": [],
                        "options": undefined,
                        "reservedRange": [],
                        "reservedName": [],
                    }],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }, {
                "name": "ListOpen",
                "field": [],
                "extension": [],
                "nestedType": [{
                        "name": "Request",
                        "field": [{
                                "name": "create_after_time",
                                "number": 1,
                                "label": 1,
                                "type": 3,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "createAfterTime",
                                "options": undefined,
                                "proto3Optional": false,
                            }],
                        "extension": [],
                        "nestedType": [],
                        "enumType": [],
                        "extensionRange": [],
                        "oneofDecl": [],
                        "options": undefined,
                        "reservedRange": [],
                        "reservedName": [],
                    }, {
                        "name": "Item",
                        "field": [{
                                "name": "tunnel_id",
                                "number": 1,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "tunnelId",
                                "options": undefined,
                                "proto3Optional": false,
                            }, {
                                "name": "endpoint",
                                "number": 2,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "endpoint",
                                "options": undefined,
                                "proto3Optional": false,
                            }, {
                                "name": "role",
                                "number": 3,
                                "label": 1,
                                "type": 14,
                                "typeName": ".subtrace.tunnel.Role",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "role",
                                "options": undefined,
                                "proto3Optional": false,
                            }],
                        "extension": [],
                        "nestedType": [],
                        "enumType": [],
                        "extensionRange": [],
                        "oneofDecl": [],
                        "options": undefined,
                        "reservedRange": [],
                        "reservedName": [],
                    }, {
                        "name": "Response",
                        "field": [{
                                "name": "error",
                                "number": 1000,
                                "label": 1,
                                "type": 9,
                                "typeName": "",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "error",
                                "options": undefined,
                                "proto3Optional": false,
                            }, {
                                "name": "tunnels",
                                "number": 1,
                                "label": 3,
                                "type": 11,
                                "typeName": ".subtrace.tunnel.ListOpen.Item",
                                "extendee": "",
                                "defaultValue": "",
                                "oneofIndex": 0,
                                "jsonName": "tunnels",
                                "options": undefined,
                                "proto3Optional": false,
                            }],
                        "extension": [],
                        "nestedType": [],
                        "enumType": [],
                        "extensionRange": [],
                        "oneofDecl": [],
                        "options": undefined,
                        "reservedRange": [],
                        "reservedName": [],
                    }],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }, {
                "name": "EventField",
                "field": [{
                        "name": "type",
                        "number": 1,
                        "label": 1,
                        "type": 5,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "type",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "tag",
                        "number": 2,
                        "label": 1,
                        "type": 5,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "tag",
                        "options": undefined,
                        "proto3Optional": false,
                    }],
                "extension": [],
                "nestedType": [],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }, {
                "name": "ClientHello",
                "field": [{
                        "name": "event_fields",
                        "number": 1,
                        "label": 3,
                        "type": 11,
                        "typeName": ".subtrace.tunnel.EventField",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "eventFields",
                        "options": undefined,
                        "proto3Optional": false,
                    }],
                "extension": [],
                "nestedType": [],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }, {
                "name": "ServerHello",
                "field": [{
                        "name": "event_fields",
                        "number": 1,
                        "label": 3,
                        "type": 11,
                        "typeName": ".subtrace.tunnel.EventField",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "eventFields",
                        "options": undefined,
                        "proto3Optional": false,
                    }],
                "extension": [],
                "nestedType": [],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }, {
                "name": "Query",
                "field": [{
                        "name": "tunnel_query_id",
                        "number": 1,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "tunnelQueryId",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "sql_statement",
                        "number": 2,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "sqlStatement",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "data",
                        "number": 3,
                        "label": 1,
                        "type": 12,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "data",
                        "options": undefined,
                        "proto3Optional": true,
                    }],
                "extension": [],
                "nestedType": [],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [{ "name": "_data", "options": undefined }],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }, {
                "name": "Result",
                "field": [{
                        "name": "tunnel_query_id",
                        "number": 1,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "tunnelQueryId",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "tunnel_error",
                        "number": 2,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "tunnelError",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "clickhouse_query_id",
                        "number": 3,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "clickhouseQueryId",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "clickhouse_error",
                        "number": 4,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "clickhouseError",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "data",
                        "number": 5,
                        "label": 1,
                        "type": 12,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "data",
                        "options": undefined,
                        "proto3Optional": false,
                    }],
                "extension": [],
                "nestedType": [],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }],
        "enumType": [{
                "name": "Role",
                "value": [{ "name": "INSERT", "number": 0, "options": undefined }, {
                        "name": "SELECT",
                        "number": 1,
                        "options": undefined,
                    }],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }],
        "service": [],
        "extension": [],
        "options": {
            "javaPackage": "",
            "javaOuterClassname": "",
            "javaMultipleFiles": false,
            "javaGenerateEqualsAndHash": false,
            "javaStringCheckUtf8": false,
            "optimizeFor": 1,
            "goPackage": "subtrace.dev/tunnel",
            "ccGenericServices": false,
            "javaGenericServices": false,
            "pyGenericServices": false,
            "phpGenericServices": false,
            "deprecated": false,
            "ccEnableArenas": true,
            "objcClassPrefix": "",
            "csharpNamespace": "",
            "swiftPrefix": "",
            "phpClassPrefix": "",
            "phpNamespace": "",
            "phpMetadataNamespace": "",
            "rubyPackage": "",
            "uninterpretedOption": [],
        },
        "sourceCodeInfo": {
            "location": [{
                    "path": [4, 0],
                    "span": [12, 0, 23, 1],
                    "leadingComments": " POST /api/CreateTunnel\n",
                    "trailingComments": "",
                    "leadingDetachedComments": [],
                }, {
                    "path": [4, 1],
                    "span": [26, 0, 41, 1],
                    "leadingComments": " POST /api/ListOpenTunnels\n",
                    "trailingComments": "",
                    "leadingDetachedComments": [],
                }],
        },
        "syntax": "proto3",
    }),
    references: {
        ".subtrace.tunnel.Role": Role,
        ".subtrace.tunnel.Create": Create,
        ".subtrace.tunnel.Create.Request": Create_Request,
        ".subtrace.tunnel.Create.Response": Create_Response,
        ".subtrace.tunnel.ListOpen": ListOpen,
        ".subtrace.tunnel.ListOpen.Request": ListOpen_Request,
        ".subtrace.tunnel.ListOpen.Item": ListOpen_Item,
        ".subtrace.tunnel.ListOpen.Response": ListOpen_Response,
        ".subtrace.tunnel.EventField": EventField,
        ".subtrace.tunnel.ClientHello": ClientHello,
        ".subtrace.tunnel.ServerHello": ServerHello,
        ".subtrace.tunnel.Query": Query,
        ".subtrace.tunnel.Result": Result,
    },
    dependencies: [],
};
function bytesFromBase64(b64) {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
        arr[i] = bin.charCodeAt(i);
    }
    return arr;
}
function base64FromBytes(arr) {
    const bin = [];
    arr.forEach((byte) => {
        bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
}
function longToBigint(long) {
    return BigInt(long.toString());
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
