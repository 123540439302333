import * as SubtraceEvent from 'ApiContracts/subtrace/event/event';
import * as Tunnel from 'ApiContracts/subtrace/tunnel/tunnel';
export function create() {
    const { fileDescriptor } = SubtraceEvent.protoMetadata;
    for (const msg of fileDescriptor.messageType) {
        if (msg.name === 'Event') {
            return Tunnel.ClientHello.encode({
                eventFields: msg.field.map((field) => ({
                    tag: field.number,
                    type: field.type,
                })),
            }).finish();
        }
    }
    throw new Error(`subtrace.event.Event missing`);
}
