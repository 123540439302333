import React from 'react';
import * as RadixUICheckbox from '@radix-ui/react-checkbox';
import { CheckIcon, DividerHorizontalIcon } from '@radix-ui/react-icons';
import * as StyleUtils from 'Utils/StyleUtils';
export function Checkbox(props) {
    const idForCheckbox = crypto.randomUUID();
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement(RadixUICheckbox.Root, { className: StyleUtils.mergeClassNames('border flex h-4 w-4 appearance-none items-center justify-center rounded-[4px] bg-white focus:shadow-[0_0_0_2px_black]', props.className), checked: props.checked, onCheckedChange: props.onCheckedChange, id: idForCheckbox },
            React.createElement(RadixUICheckbox.Indicator, null,
                props.checked === 'indeterminate' && React.createElement(DividerHorizontalIcon, null),
                props.checked === true && React.createElement(CheckIcon, null))),
        React.createElement("label", { className: StyleUtils.mergeClassNames('pl-2 text-[15px] leading-none', props.labelClassName), htmlFor: idForCheckbox }, props.label)));
}
