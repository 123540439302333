// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               v3.21.12
// source: subtrace/event/event.proto
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { FileDescriptorProto } from "ts-proto-descriptors";
export const protobufPackage = "subtrace.event";
function createBaseEvent() {
    return {
        eventId: "",
        timestamp: BigInt("0"),
        type: "",
        pid: undefined,
        hostname: undefined,
        tlsServerName: undefined,
        httpVersion: undefined,
        httpIsOutgoing: undefined,
        httpClientAddr: undefined,
        httpServerAddr: undefined,
        httpDuration: undefined,
        httpReqMethod: undefined,
        httpReqPath: undefined,
        httpRespStatusCode: undefined,
    };
}
export const Event = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.eventId !== "") {
            writer.uint32(10).string(message.eventId);
        }
        if (message.timestamp !== BigInt("0")) {
            if (BigInt.asIntN(64, message.timestamp) !== message.timestamp) {
                throw new globalThis.Error("value provided for field message.timestamp of type int64 too large");
            }
            writer.uint32(16).int64(message.timestamp.toString());
        }
        if (message.type !== "") {
            writer.uint32(26).string(message.type);
        }
        if (message.pid !== undefined) {
            if (BigInt.asIntN(64, message.pid) !== message.pid) {
                throw new globalThis.Error("value provided for field message.pid of type int64 too large");
            }
            writer.uint32(32).int64(message.pid.toString());
        }
        if (message.hostname !== undefined) {
            writer.uint32(42).string(message.hostname);
        }
        if (message.tlsServerName !== undefined) {
            writer.uint32(50).string(message.tlsServerName);
        }
        if (message.httpVersion !== undefined) {
            writer.uint32(58).string(message.httpVersion);
        }
        if (message.httpIsOutgoing !== undefined) {
            writer.uint32(64).bool(message.httpIsOutgoing);
        }
        if (message.httpClientAddr !== undefined) {
            writer.uint32(74).string(message.httpClientAddr);
        }
        if (message.httpServerAddr !== undefined) {
            writer.uint32(82).string(message.httpServerAddr);
        }
        if (message.httpDuration !== undefined) {
            if (BigInt.asIntN(64, message.httpDuration) !== message.httpDuration) {
                throw new globalThis.Error("value provided for field message.httpDuration of type int64 too large");
            }
            writer.uint32(88).int64(message.httpDuration.toString());
        }
        if (message.httpReqMethod !== undefined) {
            writer.uint32(98).string(message.httpReqMethod);
        }
        if (message.httpReqPath !== undefined) {
            writer.uint32(106).string(message.httpReqPath);
        }
        if (message.httpRespStatusCode !== undefined) {
            writer.uint32(112).int32(message.httpRespStatusCode);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.eventId = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.timestamp = longToBigint(reader.int64());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.type = reader.string();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.pid = longToBigint(reader.int64());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.hostname = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.tlsServerName = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.httpVersion = reader.string();
                    continue;
                case 8:
                    if (tag !== 64) {
                        break;
                    }
                    message.httpIsOutgoing = reader.bool();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.httpClientAddr = reader.string();
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.httpServerAddr = reader.string();
                    continue;
                case 11:
                    if (tag !== 88) {
                        break;
                    }
                    message.httpDuration = longToBigint(reader.int64());
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.httpReqMethod = reader.string();
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.httpReqPath = reader.string();
                    continue;
                case 14:
                    if (tag !== 112) {
                        break;
                    }
                    message.httpRespStatusCode = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            eventId: isSet(object.eventId) ? globalThis.String(object.eventId) : "",
            timestamp: isSet(object.timestamp) ? BigInt(object.timestamp) : BigInt("0"),
            type: isSet(object.type) ? globalThis.String(object.type) : "",
            pid: isSet(object.pid) ? BigInt(object.pid) : undefined,
            hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : undefined,
            tlsServerName: isSet(object.tlsServerName) ? globalThis.String(object.tlsServerName) : undefined,
            httpVersion: isSet(object.httpVersion) ? globalThis.String(object.httpVersion) : undefined,
            httpIsOutgoing: isSet(object.httpIsOutgoing) ? globalThis.Boolean(object.httpIsOutgoing) : undefined,
            httpClientAddr: isSet(object.httpClientAddr) ? globalThis.String(object.httpClientAddr) : undefined,
            httpServerAddr: isSet(object.httpServerAddr) ? globalThis.String(object.httpServerAddr) : undefined,
            httpDuration: isSet(object.httpDuration) ? BigInt(object.httpDuration) : undefined,
            httpReqMethod: isSet(object.httpReqMethod) ? globalThis.String(object.httpReqMethod) : undefined,
            httpReqPath: isSet(object.httpReqPath) ? globalThis.String(object.httpReqPath) : undefined,
            httpRespStatusCode: isSet(object.httpRespStatusCode) ? globalThis.Number(object.httpRespStatusCode) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.eventId !== "") {
            obj.eventId = message.eventId;
        }
        if (message.timestamp !== BigInt("0")) {
            obj.timestamp = message.timestamp.toString();
        }
        if (message.type !== "") {
            obj.type = message.type;
        }
        if (message.pid !== undefined) {
            obj.pid = message.pid.toString();
        }
        if (message.hostname !== undefined) {
            obj.hostname = message.hostname;
        }
        if (message.tlsServerName !== undefined) {
            obj.tlsServerName = message.tlsServerName;
        }
        if (message.httpVersion !== undefined) {
            obj.httpVersion = message.httpVersion;
        }
        if (message.httpIsOutgoing !== undefined) {
            obj.httpIsOutgoing = message.httpIsOutgoing;
        }
        if (message.httpClientAddr !== undefined) {
            obj.httpClientAddr = message.httpClientAddr;
        }
        if (message.httpServerAddr !== undefined) {
            obj.httpServerAddr = message.httpServerAddr;
        }
        if (message.httpDuration !== undefined) {
            obj.httpDuration = message.httpDuration.toString();
        }
        if (message.httpReqMethod !== undefined) {
            obj.httpReqMethod = message.httpReqMethod;
        }
        if (message.httpReqPath !== undefined) {
            obj.httpReqPath = message.httpReqPath;
        }
        if (message.httpRespStatusCode !== undefined) {
            obj.httpRespStatusCode = Math.round(message.httpRespStatusCode);
        }
        return obj;
    },
    create(base) {
        return Event.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        const message = createBaseEvent();
        message.eventId = (_a = object.eventId) !== null && _a !== void 0 ? _a : "";
        message.timestamp = (_b = object.timestamp) !== null && _b !== void 0 ? _b : BigInt("0");
        message.type = (_c = object.type) !== null && _c !== void 0 ? _c : "";
        message.pid = (_d = object.pid) !== null && _d !== void 0 ? _d : undefined;
        message.hostname = (_e = object.hostname) !== null && _e !== void 0 ? _e : undefined;
        message.tlsServerName = (_f = object.tlsServerName) !== null && _f !== void 0 ? _f : undefined;
        message.httpVersion = (_g = object.httpVersion) !== null && _g !== void 0 ? _g : undefined;
        message.httpIsOutgoing = (_h = object.httpIsOutgoing) !== null && _h !== void 0 ? _h : undefined;
        message.httpClientAddr = (_j = object.httpClientAddr) !== null && _j !== void 0 ? _j : undefined;
        message.httpServerAddr = (_k = object.httpServerAddr) !== null && _k !== void 0 ? _k : undefined;
        message.httpDuration = (_l = object.httpDuration) !== null && _l !== void 0 ? _l : undefined;
        message.httpReqMethod = (_m = object.httpReqMethod) !== null && _m !== void 0 ? _m : undefined;
        message.httpReqPath = (_o = object.httpReqPath) !== null && _o !== void 0 ? _o : undefined;
        message.httpRespStatusCode = (_p = object.httpRespStatusCode) !== null && _p !== void 0 ? _p : undefined;
        return message;
    },
};
export const protoMetadata = {
    fileDescriptor: FileDescriptorProto.fromPartial({
        "name": "subtrace/event/event.proto",
        "package": "subtrace.event",
        "dependency": [],
        "publicDependency": [],
        "weakDependency": [],
        "messageType": [{
                "name": "Event",
                "field": [{
                        "name": "event_id",
                        "number": 1,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "eventId",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "timestamp",
                        "number": 2,
                        "label": 1,
                        "type": 3,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "timestamp",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "type",
                        "number": 3,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "type",
                        "options": undefined,
                        "proto3Optional": false,
                    }, {
                        "name": "pid",
                        "number": 4,
                        "label": 1,
                        "type": 3,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 0,
                        "jsonName": "pid",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "hostname",
                        "number": 5,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 1,
                        "jsonName": "hostname",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "tls_server_name",
                        "number": 6,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 2,
                        "jsonName": "tlsServerName",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_version",
                        "number": 7,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 3,
                        "jsonName": "httpVersion",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_is_outgoing",
                        "number": 8,
                        "label": 1,
                        "type": 8,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 4,
                        "jsonName": "httpIsOutgoing",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_client_addr",
                        "number": 9,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 5,
                        "jsonName": "httpClientAddr",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_server_addr",
                        "number": 10,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 6,
                        "jsonName": "httpServerAddr",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_duration",
                        "number": 11,
                        "label": 1,
                        "type": 3,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 7,
                        "jsonName": "httpDuration",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_req_method",
                        "number": 12,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 8,
                        "jsonName": "httpReqMethod",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_req_path",
                        "number": 13,
                        "label": 1,
                        "type": 9,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 9,
                        "jsonName": "httpReqPath",
                        "options": undefined,
                        "proto3Optional": true,
                    }, {
                        "name": "http_resp_status_code",
                        "number": 14,
                        "label": 1,
                        "type": 5,
                        "typeName": "",
                        "extendee": "",
                        "defaultValue": "",
                        "oneofIndex": 10,
                        "jsonName": "httpRespStatusCode",
                        "options": undefined,
                        "proto3Optional": true,
                    }],
                "extension": [],
                "nestedType": [],
                "enumType": [],
                "extensionRange": [],
                "oneofDecl": [
                    { "name": "_pid", "options": undefined },
                    { "name": "_hostname", "options": undefined },
                    { "name": "_tls_server_name", "options": undefined },
                    { "name": "_http_version", "options": undefined },
                    { "name": "_http_is_outgoing", "options": undefined },
                    { "name": "_http_client_addr", "options": undefined },
                    { "name": "_http_server_addr", "options": undefined },
                    { "name": "_http_duration", "options": undefined },
                    { "name": "_http_req_method", "options": undefined },
                    { "name": "_http_req_path", "options": undefined },
                    { "name": "_http_resp_status_code", "options": undefined },
                ],
                "options": undefined,
                "reservedRange": [],
                "reservedName": [],
            }],
        "enumType": [],
        "service": [],
        "extension": [],
        "options": {
            "javaPackage": "",
            "javaOuterClassname": "",
            "javaMultipleFiles": false,
            "javaGenerateEqualsAndHash": false,
            "javaStringCheckUtf8": false,
            "optimizeFor": 1,
            "goPackage": "subtrace.dev/event",
            "ccGenericServices": false,
            "javaGenericServices": false,
            "pyGenericServices": false,
            "phpGenericServices": false,
            "deprecated": false,
            "ccEnableArenas": true,
            "objcClassPrefix": "",
            "csharpNamespace": "",
            "swiftPrefix": "",
            "phpClassPrefix": "",
            "phpNamespace": "",
            "phpMetadataNamespace": "",
            "rubyPackage": "",
            "uninterpretedOption": [],
        },
        "sourceCodeInfo": { "location": [] },
        "syntax": "proto3",
    }),
    references: { ".subtrace.event.Event": Event },
    dependencies: [],
};
function longToBigint(long) {
    return BigInt(long.toString());
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
