import * as CustomHooks from 'CustomHooks';
import * as React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import * as StyleUtils from 'Utils/StyleUtils';
import { Button } from 'DesignComponents/Button';
import { Link } from 'react-router-dom';
export function OldLandingPage() {
    const navigate = ReactRouterDom.useNavigate();
    const imageRef = React.useRef(null);
    const [imageRotationAngle, setImageRotationAngle] = React.useState(undefined);
    const isLoggedIn = CustomHooks.useIsLoggedIn();
    if (isLoggedIn === undefined) {
        return React.createElement("div", { className: "w-dvw h-dvh flex flex-col items-center bg-gray-900" });
    }
    return (React.createElement("div", { className: "w-dvw h-dvh flex flex-col items-center bg-gray-900", onMouseMove: onMouseMove },
        React.createElement("div", { className: "h-16 w-dvw bg-gray-900 text-white flex items-center justify-end border-gray-500 border-b px-5" }, isLoggedIn ? (React.createElement(Button, { className: StyleUtils.mergeClassNames('px-3 py-1 rounded-md', 'hover:bg-white hover:text-gray-900'), label: "Go to dashboard", onClick: () => navigate('/dashboard') })) : (React.createElement(Button, { className: StyleUtils.mergeClassNames('px-3 py-1 rounded-md', 'hover:bg-white hover:text-gray-900'), label: "Log in", onClick: () => navigate('/login') }))),
        React.createElement("div", { className: "text-white font-sans text-9xl my-3" }, "Subtres"),
        React.createElement("img", { height: 400, width: 300, ref: imageRef, src: "https://upload.wikimedia.org/wikipedia/en/9/96/Meme_Man_on_transparent_background.webp", style: { transform: `rotate(${imageRotationAngle}rad)` } }),
        React.createElement("div", { className: "text-white font-sans text-4xl flex flex-col" },
            React.createElement("span", { className: "mb-4" },
                ' ',
                "1. Install",
                ' ',
                React.createElement("a", { className: "text-blue-700 underline", href: "https://github.com/subtrace/subtrace" }, "subtrace")),
            React.createElement("span", { className: "mb-4" }, "2. Run your server under subtrace"),
            React.createElement("span", { className: "mb-6" },
                "3. See your traces on the",
                ' ',
                React.createElement(Link, { to: "/dashboard" },
                    React.createElement("span", { className: "text-blue-700 underline" }, "dashboard"))))));
    function getRotationAngle(mouse_x, mouse_y) {
        if (!imageRef.current) {
            return undefined;
        }
        const imageRectangle = imageRef.current.getBoundingClientRect();
        const image_x = (imageRectangle.left + imageRectangle.right) / 2;
        const image_y = (imageRectangle.top + imageRectangle.bottom) / 2;
        return Math.atan2(mouse_y - image_y, mouse_x - image_x);
    }
    function onMouseMove(event) {
        setImageRotationAngle(getRotationAngle(event.clientX, event.clientY));
    }
}
