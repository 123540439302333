import React from 'react';
import * as StyleUtils from 'Utils/StyleUtils';
export function TextField(props) {
    return (React.createElement("div", { className: (StyleUtils.mergeClassNames('flex flex-col'), props.className) },
        props.label ? React.createElement("span", { className: "text-white text-sm" }, props.label) : null,
        React.createElement("input", { className: "w-full h-6 appearance-none items-center justify-center rounded-[4px] px-1 text-[13px] leading-none border border-white text-white bg-gray-900", type: "text", value: props.value, onChange: onValueChange }),
        props.errorMessage ? React.createElement("span", { className: "text-red-600 text-xs" }, props.errorMessage) : null));
    function onValueChange(event) {
        props.onChange(event.target.value);
    }
}
