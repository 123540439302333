import * as ApiEndpointUtils from 'ApiEndpointUtils';
export async function get(path, options) {
    return performApiRequest(path, 'GET', /* body */ undefined, options);
}
export async function post(path, body, options) {
    return performApiRequest(path, 'POST', body, options);
}
async function performApiRequest(path, method, body, options) {
    const { headers, signal } = options;
    return fetch(`${ApiEndpointUtils.getApiEndpoint()}${path}`, {
        credentials: 'include',
        headers,
        method,
        signal,
        body: JSON.stringify(body),
    });
}
