// ref: https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
export var HttpMethodName;
(function (HttpMethodName) {
    HttpMethodName["CONNECT"] = "CONNECT";
    HttpMethodName["DELETE"] = "DELETE";
    HttpMethodName["GET"] = "GET";
    HttpMethodName["HEAD"] = "HEAD";
    HttpMethodName["OPTIONS"] = "OPTIONS";
    HttpMethodName["POST"] = "POST";
    HttpMethodName["PUT"] = "PUT";
    HttpMethodName["TRACE"] = "TRACE";
})(HttpMethodName || (HttpMethodName = {}));
