const MAX_VARINT_LEN_64 = 10;
export function readFromBuffer(bytes, startIndex) {
    let currentByteIndex = startIndex;
    let currentVarInt = 0;
    let currentVarIntLength = 0;
    while (currentByteIndex < bytes.length) {
        const currentByte = bytes[currentByteIndex];
        if (currentByteIndex - startIndex + 1 > MAX_VARINT_LEN_64) {
            throw new Error(`Integer overflow when trying to read varint at buffer position ${startIndex}`);
        }
        if (currentByte < 0x80) {
            if (currentByte - startIndex + 1 === MAX_VARINT_LEN_64) {
                throw new Error(`Integer overflow when trying to read varint at buffer position ${startIndex}`);
            }
            return [currentVarInt | (currentByte << currentVarIntLength), currentByteIndex - startIndex + 1];
        }
        currentVarInt |= (currentByte & 0x7f) << currentVarIntLength;
        currentVarIntLength += 7;
        currentByteIndex++;
    }
    throw new Error(`Buffer overflow when trying to read varint at buffer position ${startIndex}`);
}
