import React from 'react';
import * as StyleUtils from 'Utils/StyleUtils';
import { Spinner } from './Spinner';
export function Button(props) {
    var _a;
    return (React.createElement("button", { "aria-label": (_a = props.ariaLabel) !== null && _a !== void 0 ? _a : props.label, className: StyleUtils.mergeClassNames('border text-base', 'disabled:bg-gray-400 disabled:text-gray-300', props.className), disabled: props.disabled, onClick: props.onClick },
        React.createElement("span", { className: "flex justify-center" },
            props.showSpinner ? React.createElement(Spinner, { className: "mr-[2px]" }) : null,
            props.label)));
}
