import * as React from 'react';
import { Button } from 'DesignComponents/Button';
import { Event as SubtraceEvent } from 'ApiContracts/subtrace/event/event';
import { Grid } from './Grid';
import { HttpMethodName } from 'HttpMethodName';
import { MultiSelectFilterButton } from './MultiSelectFilterButton';
import { NumericalFilterButton } from 'NumericalFilterButton';
import { Spinner } from 'DesignComponents/Spinner';
import { TextFilterButton } from './TextFilterButton';
import { ZIndex } from './ZIndex';
import * as CustomHooks from 'CustomHooks';
import * as ResultUtils from 'Utils/ResultUtils';
import * as StyleUtils from 'Utils/StyleUtils';
import * as Tunnel from 'ApiContracts/subtrace/tunnel/tunnel';
import * as UIQueryUtils from 'UIQueryUtils';
import * as VarintUtils from 'VarintUtils';
export function AppContents(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    // TODO: Unify gridData and fetchError into an AsyncValue like interface
    const [gridData, setGridData] = React.useState(undefined);
    const [currentEvaluatingQuery, setCurrentEvaluatingQuery] = React.useState({
    // Empty query means that no filters have been applied
    });
    const [currentAppliedQuery, setCurrentAppliedQuery] = React.useState({
    // Empty query means that no filters have been applied
    });
    const initializedWebSocket = CustomHooks.useInitializedWebSocket();
    const handleWebsocketMessage = React.useCallback((message) => {
        const result = Tunnel.Result.decode(new Uint8Array(message.data));
        if (result.tunnelError) {
            // TODO: Graceful error handling
            window.alert(result.tunnelError);
            return;
        }
        if (result.clickhouseError) {
            // TODO: Graceful error handling
            window.alert(result.clickhouseError);
            return;
        }
        let currentByteIndex = 0;
        const events = [];
        while (currentByteIndex < result.data.byteLength) {
            const [eventSize, bytesRead] = VarintUtils.readFromBuffer(result.data, currentByteIndex);
            currentByteIndex += bytesRead;
            events.push(SubtraceEvent.decode(result.data.slice(currentByteIndex, currentByteIndex + eventSize)));
            currentByteIndex += eventSize;
        }
        setGridData(events);
        setCurrentAppliedQuery(currentEvaluatingQuery);
        setCurrentEvaluatingQuery(undefined);
    }, [currentEvaluatingQuery]);
    React.useEffect(() => {
        if (initializedWebSocket && ResultUtils.isSuccess(initializedWebSocket) && currentEvaluatingQuery) {
            const webSocket = initializedWebSocket.value;
            const sqlQueryMessage = Tunnel.Query.encode({
                sqlStatement: UIQueryUtils.getSqlQueryString(currentEvaluatingQuery),
                tunnelQueryId: crypto.randomUUID(),
            }).finish();
            webSocket.onmessage = handleWebsocketMessage;
            webSocket.send(sqlQueryMessage);
        }
    }, [currentEvaluatingQuery, handleWebsocketMessage, initializedWebSocket]);
    if (initializedWebSocket && ResultUtils.isFailure(initializedWebSocket)) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, "There was an error fetching the requests:"),
            React.createElement("div", null, initializedWebSocket.error)));
    }
    return (
    // TODO: Should this be refactored so that we only have one div in this file that has props.className?
    React.createElement("div", { className: StyleUtils.mergeClassNames('flex flex-col', props.className) },
        React.createElement("div", { className: "ml-3 grow" },
            React.createElement("div", { className: "mb-3 flex flex-col" },
                React.createElement("div", { className: "flex flex-row mb-3" },
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "Event ID", initialFilter: (_a = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.eventIdFilter) !== null && _a !== void 0 ? _a : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.eventIdFilter, onFilterCommitted: (filter) => onFilterCommitted('eventIdFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(NumericalFilterButton, { className: "mr-3", filterParameterName: "Timestamp", initialFilter: (_b = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.timestampFilter) !== null && _b !== void 0 ? _b : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.timestampFilter, onFilterCommitted: (filter) => onFilterCommitted('timestampFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "Type", initialFilter: (_c = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.typeFilter) !== null && _c !== void 0 ? _c : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.typeFilter, onFilterCommitted: (filter) => onFilterCommitted('typeFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(NumericalFilterButton, { className: "mr-3", filterParameterName: "PID", initialFilter: (_d = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.pidFilter) !== null && _d !== void 0 ? _d : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.pidFilter, onFilterCommitted: (filter) => onFilterCommitted('pidFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "Host name", initialFilter: (_e = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.hostNameFilter) !== null && _e !== void 0 ? _e : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.hostNameFilter, onFilterCommitted: (filter) => onFilterCommitted('hostNameFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "TLS server name", initialFilter: (_f = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.tlsServerNameFilter) !== null && _f !== void 0 ? _f : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.tlsServerNameFilter, onFilterCommitted: (filter) => onFilterCommitted('tlsServerNameFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "HTTP version", initialFilter: (_g = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.versionFilter) !== null && _g !== void 0 ? _g : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.versionFilter, onFilterCommitted: (filter) => onFilterCommitted('versionFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(MultiSelectFilterButton, { className: "mr-3", filterParameterName: "HTTP is outgoing", initialFilter: (_h = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.isOutgoingFilter) !== null && _h !== void 0 ? _h : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.isOutgoingFilter, items: getIsOutgoingFilterItems(), onFilterCommitted: (filter) => onFilterCommitted('isOutgoingFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "HTTP client address", initialFilter: (_j = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.clientAddressFilter) !== null && _j !== void 0 ? _j : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.clientAddressFilter, onFilterCommitted: (filter) => onFilterCommitted('clientAddressFilter', filter), zIndex: ZIndex.FilterCallout })),
                React.createElement("div", { className: "flex flex-row" },
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "HTTP server address", initialFilter: (_k = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.serverAddressFilter) !== null && _k !== void 0 ? _k : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.serverAddressFilter, onFilterCommitted: (filter) => onFilterCommitted('serverAddressFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(NumericalFilterButton, { className: "mr-3", filterParameterName: "HTTP duration", initialFilter: (_l = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.durationFilter) !== null && _l !== void 0 ? _l : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.durationFilter, onFilterCommitted: (filter) => onFilterCommitted('durationFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(MultiSelectFilterButton, { className: "mr-3", filterParameterName: "HTTP request method", initialFilter: (_m = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.methodNameFilter) !== null && _m !== void 0 ? _m : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.methodNameFilter, items: getMethodNameFilterItems(), onFilterCommitted: (filter) => onFilterCommitted('methodNameFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(TextFilterButton, { className: "mr-3", filterParameterName: "HTTP request path", initialFilter: (_o = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.pathFilter) !== null && _o !== void 0 ? _o : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.pathFilter, onFilterCommitted: (filter) => onFilterCommitted('pathFilter', filter), zIndex: ZIndex.FilterCallout }),
                    React.createElement(MultiSelectFilterButton, { className: "mr-3", filterParameterName: "HTTP response status", initialFilter: (_p = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.statusCodeFilter) !== null && _p !== void 0 ? _p : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.statusCodeFilter, items: getStatusCodeFilterItems(), onFilterCommitted: (filter) => onFilterCommitted('statusCodeFilter', filter), zIndex: ZIndex.FilterCallout }),
                    currentEvaluatingQuery ? React.createElement(Spinner, null) : null)),
            React.createElement(Grid, { className: "h-[500px] w-[1500px]", columnDefinitions: [
                    { headerName: 'Event ID', field: 'eventId' },
                    { headerName: 'Timestamp', field: 'timestamp' },
                    { headerName: 'Type', field: 'type' },
                    { headerName: 'PID', field: 'pid' },
                    { headerName: 'Host name', field: 'hostname' },
                    { headerName: 'TLS server name', field: 'tlsServerName' },
                    { headerName: 'HTTP version', field: 'httpVersion' },
                    {
                        headerName: 'HTTP is outgoing',
                        field: 'httpIsOutgoing',
                        cellRenderer: 'agGroupCellRenderer',
                    },
                    { headerName: 'HTTP client address', field: 'httpClientAddr' },
                    { headerName: 'HTTP server address', field: 'httpServerAddr' },
                    { headerName: 'HTTP duration (ns)', field: 'httpDuration' },
                    { headerName: 'HTTP request method', field: 'httpReqMethod' },
                    { headerName: 'HTTP request path', field: 'httpReqPath' },
                    { headerName: 'HTTP response status code', field: 'httpRespStatusCode' },
                ], rowData: gridData !== null && gridData !== void 0 ? gridData : [] }),
            React.createElement("div", { className: "mt-5" },
                React.createElement(Button, { className: "rounded-md px-2 h-8 bg-blue-400 text-white", disabled: !currentEvaluatingQuery, label: "Cancel evaluation", onClick: cancelCurrentEvaluatingRequest, showSpinner: !!currentEvaluatingQuery })))));
    function cancelCurrentEvaluatingRequest() {
        setCurrentEvaluatingQuery(undefined);
    }
    function getIsOutgoingFilterItems() {
        return [true, false, undefined].map((value) => ({
            displayString: value !== undefined ? value.toString() : '(null)',
            itemKey: value !== undefined ? value.toString() : '(null)',
            value,
            itemCount: gridData === null || gridData === void 0 ? void 0 : gridData.filter((event) => event.httpIsOutgoing === value).length,
        }));
    }
    function getMethodNameFilterItems() {
        return Object.values(HttpMethodName).map((method) => ({
            displayString: method,
            itemKey: method,
            value: method,
            itemCount: gridData === null || gridData === void 0 ? void 0 : gridData.filter((event) => event.httpReqMethod === method).length,
        }));
    }
    function getStatusCodeFilterItems() {
        if (!gridData) {
            return [];
        }
        const countsByStatusCode = {};
        for (const { httpRespStatusCode: statusCode } of gridData) {
            if (!statusCode) {
                continue;
            }
            if (!(statusCode in countsByStatusCode)) {
                countsByStatusCode[statusCode] = 0;
            }
            countsByStatusCode[statusCode]++;
        }
        return Object.entries(countsByStatusCode).map(([code, count]) => ({
            displayString: code,
            itemKey: code,
            value: parseInt(code),
            itemCount: count,
        }));
    }
    function onFilterCommitted(filterKey, filter) {
        setCurrentEvaluatingQuery(Object.assign(Object.assign(Object.assign({}, currentAppliedQuery), currentEvaluatingQuery), { [filterKey]: filter }));
    }
}
