export var NumericalFilterKind;
(function (NumericalFilterKind) {
    NumericalFilterKind["Between"] = "Between";
    NumericalFilterKind["Equals"] = "Equals";
    NumericalFilterKind["GreaterThan"] = "GreaterThan";
    NumericalFilterKind["GreaterThanOrEquals"] = "GreaterThanOrEquals";
    NumericalFilterKind["LesserThan"] = "LesserThan";
    NumericalFilterKind["LesserThanOrEquals"] = "LesserThanOrEquals";
    NumericalFilterKind["NotEquals"] = "NotEquals";
})(NumericalFilterKind || (NumericalFilterKind = {}));
