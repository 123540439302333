import * as SubtraceEvent from 'ApiContracts/subtrace/event/event';
import * as MapUtils from 'Utils/MapUtils';
import * as Verify from 'Utils/Verify';
import { NumericalFilterKind } from 'NumericalFilter';
import { TextFilterKind } from 'TextFilter';
let cachedColumnNames = undefined;
function getSqlColumnNameByJsonColumnName() {
    if (cachedColumnNames) {
        return cachedColumnNames;
    }
    const { fileDescriptor } = SubtraceEvent.protoMetadata;
    for (const msg of fileDescriptor.messageType) {
        if (msg.name === 'Event') {
            const sqlColumnNameByJsonColumnName = new Map();
            for (const { jsonName, number } of msg.field) {
                sqlColumnNameByJsonColumnName.set(jsonName, `T${(0x100000000 + number).toString(16).substring(1)}`);
            }
            cachedColumnNames = sqlColumnNameByJsonColumnName;
            return sqlColumnNameByJsonColumnName;
        }
    }
    throw new Error(`subtrace.event.Event missing`);
}
function getSqlColumnName(jsonColumnName) {
    return MapUtils.getOrThrow(getSqlColumnNameByJsonColumnName(), jsonColumnName);
}
function numericalFilterToClauseString(filter, jsonColumnName) {
    if (!filter) {
        return undefined;
    }
    const sqlColumnName = getSqlColumnName(jsonColumnName);
    switch (filter.filterKind) {
        case NumericalFilterKind.Between:
            return `${sqlColumnName} >= ${filter.lowerBound.toString()} AND ${sqlColumnName} <= ${filter.upperBound.toString()}`;
        case NumericalFilterKind.Equals:
            return `${sqlColumnName} = ${filter.filterValue.toString()}`;
        case NumericalFilterKind.GreaterThan:
            return `${sqlColumnName} > ${filter.filterValue.toString()}`;
        case NumericalFilterKind.GreaterThanOrEquals:
            return `${sqlColumnName} >= ${filter.filterValue.toString()}`;
        case NumericalFilterKind.LesserThan:
            return `${sqlColumnName} < ${filter.filterValue.toString()}`;
        case NumericalFilterKind.LesserThanOrEquals:
            return `${sqlColumnName} <= ${filter.filterValue.toString()}`;
        case NumericalFilterKind.NotEquals:
            return `${sqlColumnName} != ${filter.filterValue.toString()}`;
    }
}
function multiSelectFilterToClauseString(filter, jsonColumnName, getStringRepresentation = (value) => JSON.stringify(value)) {
    if (!filter) {
        return undefined;
    }
    const sqlColumnName = getSqlColumnName(jsonColumnName);
    return `${sqlColumnName} IN (${filter.values.map((value) => getStringRepresentation(value)).join(', ')})`;
}
function textFilterToClauseString(filter, jsonColumnName) {
    if (!filter) {
        return undefined;
    }
    const sqlColumnName = getSqlColumnName(jsonColumnName);
    switch (filter.filterKind) {
        case TextFilterKind.Contains:
            return `${sqlColumnName} LIKE '%${sanitizeStringValueForClickhouse(filter.filterValue)}%'`;
        case TextFilterKind.DoesNotContain:
            return `${sqlColumnName} NOT LIKE '%${sanitizeStringValueForClickhouse(filter.filterValue)}%'`;
        case TextFilterKind.Equals:
            return `${sqlColumnName} = '%${sanitizeStringValueForClickhouse(filter.filterValue)}%'`;
        case TextFilterKind.DoesNotEqual:
            return `${sqlColumnName} != '${sanitizeStringValueForClickhouse(filter.filterValue)}'`;
        case TextFilterKind.EndsWith:
            return `${sqlColumnName} LIKE '%${sanitizeStringValueForClickhouse(filter.filterValue)}'`;
        case TextFilterKind.DoesNotEndWith:
            return `${sqlColumnName} NOT LIKE '%${sanitizeStringValueForClickhouse(filter.filterValue)}'`;
        case TextFilterKind.StartsWith:
            return `${sqlColumnName} LIKE '${sanitizeStringValueForClickhouse(filter.filterValue)}%'`;
        case TextFilterKind.DoesNotStartWith:
            return `${sqlColumnName} NOT LIKE '${sanitizeStringValueForClickhouse(filter.filterValue)}%'`;
        case TextFilterKind.IsNull:
            return `${sqlColumnName} IS NULL`;
        case TextFilterKind.IsNotNull:
            return `${sqlColumnName} IS NOT NULL`;
        default:
            Verify.isNever(filter);
    }
}
function sanitizeStringValueForClickhouse(value) {
    // ref: https://clickhouse.com/docs/en/sql-reference/syntax#string
    return value.replace(/['\\]/g, '\\&');
}
export function getSqlQueryString(query) {
    if (!query) {
        return `SELECT * FROM events FORMAT Protobuf;`;
    }
    const clauses = [
        textFilterToClauseString(query.clientAddressFilter, 'httpClientAddr'),
        numericalFilterToClauseString(query.durationFilter, 'httpDuration'),
        textFilterToClauseString(query.eventIdFilter, 'eventId'),
        textFilterToClauseString(query.hostNameFilter, 'hostname'),
        multiSelectFilterToClauseString(query.isOutgoingFilter, 'httpIsOutgoing'),
        multiSelectFilterToClauseString(query.methodNameFilter, 'httpReqMethod', 
        /* getStringRepresentation */ (value) => `'${value.toString()}'`),
        textFilterToClauseString(query.pathFilter, 'httpReqPath'),
        numericalFilterToClauseString(query.pidFilter, 'pid'),
        textFilterToClauseString(query.versionFilter, 'httpVersion'),
        multiSelectFilterToClauseString(query.statusCodeFilter, 'httpRespStatusCode'),
        textFilterToClauseString(query.serverAddressFilter, 'httpServerAddr'),
        numericalFilterToClauseString(query.timestampFilter, 'timestamp'),
        textFilterToClauseString(query.tlsServerNameFilter, 'tlsServerName'),
        textFilterToClauseString(query.typeFilter, 'type'),
    ]
        .filter((clause) => clause !== undefined)
        .map((clause) => `(${clause})`);
    return clauses.length > 0
        ? `SELECT * FROM events WHERE (${clauses.join(' AND ')}) FORMAT Protobuf;`
        : `SELECT * FROM events FORMAT Protobuf;`;
}
