import * as React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import * as ApiUtils from 'ApiUtils';
import * as StyleUtils from 'Utils/StyleUtils';
import { Button } from 'DesignComponents/Button';
export function AppTopBar() {
    const navigate = ReactRouterDom.useNavigate();
    const [isFetchingApiToken, setIsFetchingApiToken] = React.useState(false);
    return (React.createElement("div", { className: "h-16 bg-gray-900 text-white flex items-center justify-between border-gray-500 border-b px-5" },
        React.createElement("span", { className: "text-3xl" },
            "Subtr",
            React.createElement("span", { className: "text-blue-400" }, "\u03B1"),
            "ce"),
        React.createElement("div", { className: "flex" },
            React.createElement(Button, { className: StyleUtils.mergeClassNames('px-3 py-1 rounded-md', 'hover:bg-white hover:text-gray-900'), disabled: isFetchingApiToken, label: "Get API token", onClick: getApiToken, showSpinner: isFetchingApiToken }),
            React.createElement(Button, { className: StyleUtils.mergeClassNames('px-3 py-1 rounded-md ml-2', 'hover:bg-white hover:text-gray-900'), label: "Log out", onClick: logOut }))));
    async function getApiToken() {
        try {
            setIsFetchingApiToken(true);
            const response = await (await ApiUtils.post('/api/GenerateToken', /* body */ {}, /* headers */ {})).json();
            if (response.error) {
                // TODO: graceful error handling
            }
            else {
                navigator.clipboard.writeText(response.token);
                window.alert('Token copied to clipboard');
            }
        }
        catch (error) {
            // TODO: Add logging/telemetry
        }
        finally {
            setIsFetchingApiToken(false);
        }
    }
    async function logOut() {
        try {
            const response = await ApiUtils.post('/api/LogOut', /* body */ {}, /* options */ {});
            if (response.status === 200) {
                navigate('/login');
            }
            else {
                // TODO: Add logging/telemetry
            }
        }
        catch (error) {
            // TODO: Add logging/telemetry
        }
    }
}
