import * as React from 'react';
import * as StyleUtils from 'Utils/StyleUtils';
import AWSLogo from '../images/aws_logo.svg';
import AzureLogo from '../images/azure_logo.svg';
import GCPLogo from '../images/gcp_logo.svg';
import ProductDemoImage from '../images/product_demo.png';
function getRandomLanguage(seed) {
    const choices = {
        'for Python apps': 0.5,
        'for Node.js servers': 0.3,
        'for Go backends': 0.2,
    };
    let total = 0;
    for (const [choice, weight] of Object.entries(choices)) {
        if (total + weight >= seed) {
            return choice;
        }
        total += weight;
    }
    throw new Error('unreachable');
}
function TitleText() {
    const seed = React.useState(Math.random())[0];
    const texts = [
        'for Kubernetes pods',
        `${getRandomLanguage(seed)}`,
        'for virtual machines',
        'for Docker containers',
        'that just works',
    ];
    const [textIndex, setTextIndex] = React.useState(0);
    const [textFade, setTextFade] = React.useState('opacity-100 translate-y-[0rem]');
    React.useEffect(() => {
        let interval;
        if (textIndex !== texts.length - 1) {
            interval = setInterval(() => {
                setTextFade('translate-y-[1rem] opacity-0 blur-[1rem]');
                setTimeout(() => {
                    setTextFade('-translate-y-[1rem] opacity-0 blur-[1rem]');
                    setTimeout(() => {
                        setTextFade('');
                        setTextIndex((textIndex + 1) % texts.length);
                    }, 75);
                }, 75);
            }, /* milliseconds */ 3000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [textIndex, texts.length]);
    return (React.createElement("div", { className: "mt-20 flex justify-center" },
        React.createElement("h2", { className: "font-semibold text-6xl max-w-3xl flex flex-col items-center p-4 text-zinc-100" },
            React.createElement("span", null, "Automatic tracing"),
            React.createElement("span", { className: `mt-2 transition-all duration-[0.05s] ease-in-out ${textFade}` },
                React.createElement("span", null, texts[textIndex])))));
}
function PolkaDots(props) {
    return (React.createElement("div", { style: {
            backgroundImage: `radial-gradient(#ffffff0d 10%, transparent 10%), radial-gradient(#ffffff0d 10%, transparent 10%)`,
            backgroundPosition: '0px 0px, 8px 8px',
            backgroundSize: '16px 16px',
        }, className: `pointer-events-none overflow-hidden absolute left-0 top-0 w-full h-full ${props.color}` }));
}
function SVG(props) {
    return (React.createElement("div", { className: `flex justify-center items-center ${props.className}`, dangerouslySetInnerHTML: { __html: props.data } }));
}
function Orbit(props) {
    var _a;
    const size = (_a = props.children.length) !== null && _a !== void 0 ? _a : 1;
    const wrapped = [];
    for (let i = 0; i < 12; i++) {
        wrapped.push(React.createElement("div", { key: i, className: "absolute", style: {
                transform: `rotate(${90 - (i * 360) / size / 4}deg)`,
                left: `calc(50% - 16px + ${props.radius * Math.cos((i * Math.PI) / size / 2)}px)`,
                top: `calc(50% - 16px - ${props.radius * Math.sin((i * Math.PI) / size / 2)}px)`,
            } }, props.children[i % size]));
    }
    return (React.createElement("div", { className: "absolute top-0 left-0 w-full h-full" },
        React.createElement("div", { className: StyleUtils.mergeClassNames('relative rounded-full border border-dashed border-zinc-700', props.radius > 300 ? 'animate-spin-slow-2' : 'animate-spin-slow-1', props.radius > 300 ? 'rotate-90' : ''), style: {
                top: `calc(120% - ${props.radius}px)`,
                left: `calc(50% - ${props.radius}px)`,
                width: `${2 * props.radius}px`,
                height: `${2 * props.radius}px`,
            } }, wrapped)));
}
function Integrations() {
    return (React.createElement("div", { className: "overflow-hidden absolute left-0 top-0 w-full h-full" },
        React.createElement("div", { className: "relative top-0 left-0 w-full h-full" },
            React.createElement("div", { className: "absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem]", style: {
                    background: 'radial-gradient(#07598560 0%, #07598540 25%, transparent 50%, transparent 100%)',
                } }),
            React.createElement(Orbit, { radius: 360 },
                React.createElement(SVG, { className: "w-8 h-8 contrast-[0.50] grayscale brightness-[0.50]", data: AWSLogo }),
                React.createElement(SVG, { className: "w-8 h-8 contrast-[0.50] grayscale brightness-[0.50]", data: AzureLogo }),
                React.createElement(SVG, { className: "w-8 h-8 contrast-[0.50] grayscale brightness-[0.50]", data: GCPLogo })),
            React.createElement(Orbit, { radius: 240 },
                React.createElement(SVG, { className: "w-8 h-8 contrast-[0.50] grayscale brightness-[0.50]", data: AWSLogo }),
                React.createElement(SVG, { className: "w-8 h-8 contrast-[0.50] grayscale brightness-[0.50]", data: AzureLogo }),
                React.createElement(SVG, { className: "w-8 h-8 contrast-[0.50] grayscale brightness-[0.50]", data: GCPLogo })))));
}
export function NewLandingPage() {
    return (React.createElement("div", { className: "w-full h-vh flex flex-col bg-black" },
        React.createElement("div", { className: "flex justify-center mt-12" },
            React.createElement("div", { className: "w-full max-w-6xl py-2" },
                React.createElement("div", { className: "float-left my-auto flex items-center space-x-4" },
                    React.createElement("a", { href: "/", className: "px-4 py-2 text-md tracking-widest rounded font-medium text-white font-mono mr-4" }, "subtrace"),
                    React.createElement("a", { href: "/docs", className: "px-4 py-2 text-sm font-medium text-zinc-300/95 hover:text-zinc-300" }, "Docs")),
                React.createElement("div", { className: "float-right my-auto flex items-center space-x-4" },
                    React.createElement("a", { href: "/login", className: "px-4 py-2 text-sm font-medium text-zinc-300/95 hover:text-zinc-300" }, "Log in"),
                    React.createElement("a", { target: "_blank", href: "https://cal.com/subtrace/30min", rel: "noreferrer", className: "px-6 py-2 outline outline-4 outline-sky-900/60 text-sm text-white font-medium rounded-full bg-sky-700/95 hover:bg-sky-700 group" },
                        React.createElement("span", { className: "ml-[2px]" }, "Start"),
                        React.createElement("span", { className: "inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]" }, "\u2192"))))),
        React.createElement(TitleText, null),
        React.createElement("div", { className: "mt-8 flex justify-center" },
            React.createElement("div", { className: "text-zinc-300 max-w-md text-center" }, "Subtrace is the easiest way to add tracing to your infra. Works out of the box. Built for developers.")),
        React.createElement("div", { className: "mt-16 flex justify-center" },
            React.createElement("div", { className: "max-w-md flex justify-end items-center space-x-6" },
                React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://cal.com/subtrace/30min", className: "px-6 py-2 outline outline-4 outline-sky-900/60 text-sm text-white font-medium rounded-full bg-sky-700/95 hover:bg-sky-700 group" },
                    React.createElement("span", { className: "ml-[2px]" }, "Start"),
                    React.createElement("span", { className: "inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]" }, "\u2192")),
                React.createElement("a", { href: "/docs", className: "px-6 py-2 text-sm text-zinc-200/95 font-medium rounded-full hover:text-zinc-100 group transition hover:bg-zinc-900" },
                    React.createElement("span", { className: "ml-[2px]" }, "Docs"),
                    React.createElement("span", { className: "inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]" }, "\u2192")))),
        React.createElement("div", { className: "m-40 mt-20 flex justify-center" },
            React.createElement("img", { className: "max-w-5xl", src: ProductDemoImage, alt: "Dashboard" })),
        React.createElement("div", { className: "mt-20 flex justify-center" },
            React.createElement("div", { className: "flex flex-col space-y-8" },
                React.createElement("div", { className: "flex space-x-8 items-center text-xl font-medium" },
                    React.createElement("div", { className: "relative overflow-hidden w-full h-96 px-8 py-6 bg-zinc-900/70 rounded-xl" },
                        React.createElement("span", { className: "text-zinc-300" }, "Works the same everywhere."),
                        React.createElement("span", null, " "),
                        React.createElement("span", { className: "text-zinc-400" }, "Every language. Every cloud."),
                        React.createElement(Integrations, null))),
                React.createElement("div", { className: "flex space-x-8 items-center text-lg font-medium" },
                    React.createElement("div", { className: "relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl" },
                        React.createElement("span", { className: "text-zinc-300" }, "Bring your own storage."),
                        React.createElement("span", null, " "),
                        React.createElement("span", { className: "text-zinc-400" }, "Regain control over your company's data security. Your tracing data stays in your own servers.")),
                    React.createElement("div", { className: "relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl" },
                        React.createElement("span", { className: "text-zinc-300" }, "Zero config."),
                        React.createElement("span", null, " "),
                        React.createElement("span", { className: "text-zinc-400" }, "Subtrace fits into your existing backend out of the box. No code changes required."))),
                React.createElement("div", { className: "flex space-x-8 items-center text-sm" },
                    React.createElement("div", { className: "relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl" },
                        React.createElement(PolkaDots, { color: "bg-zinc-800/20" }),
                        React.createElement("span", { className: "font-medium text-zinc-200" }, "Made for startups."),
                        React.createElement("span", null, " "),
                        React.createElement("span", { className: "font-normal text-zinc-300/80" }, "Spend more time on what's important for your product.")),
                    React.createElement("div", { className: "relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl" },
                        React.createElement(PolkaDots, { color: "bg-zinc-800/20" }),
                        React.createElement("span", { className: "font-medium text-zinc-200" }, "Move fast, search things."),
                        React.createElement("span", null, " "),
                        React.createElement("span", { className: "font-normal text-zinc-300/80" }, "Subtrace is built on ClickHouse, the fastest database for analytics.")),
                    React.createElement("div", { className: "relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl" },
                        React.createElement(PolkaDots, { color: "bg-zinc-800/20" }),
                        React.createElement("span", { className: "font-medium text-zinc-200" }, "Fixed pricing."),
                        React.createElement("span", null, " "),
                        React.createElement("span", { className: "font-normal text-zinc-300/80" }, "Get a predictable bill every month. No surprises.")),
                    React.createElement("div", { className: "relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl" },
                        React.createElement(PolkaDots, { color: "bg-zinc-800/20" }),
                        React.createElement("span", { className: "font-medium text-zinc-200" }, "Open source."),
                        React.createElement("span", null, " "),
                        React.createElement("a", { className: "font-normal text-zinc-300/80 hover:text-zinc-300", href: "https://github.com/subtrace/subtrace" }, "Star us on GitHub."))),
                React.createElement("div", { className: "w-full h-24 flex p-4 bg-zinc-900 rounded-xl" },
                    React.createElement("div", { className: "w-full flex items-center justify-between px-4" },
                        React.createElement("div", { className: "text-xl font-medium flex items-center p-4 text-zinc-200" },
                            React.createElement("span", { className: "text-white" }, "Try for free."),
                            "\u00A0",
                            React.createElement("span", { className: "text-zinc-400" }, "Schedule a demo.")),
                        React.createElement("div", { className: "px-4 relative" },
                            React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://cal.com/subtrace/30min", className: "px-6 py-2 outline outline-4 outline-sky-900/60 text-sm text-white font-medium rounded-full bg-sky-700/95 hover:bg-sky-700 group" },
                                React.createElement("span", { className: "ml-[2px]" }, "Schedule"),
                                React.createElement("span", { className: "inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]" }, "\u2192"))))))),
        React.createElement("div", { className: "mt-20 flex justify-center" },
            React.createElement("div", { className: "w-full max-w-3xl py-2 mt-16 mb-32" },
                React.createElement("div", { className: "flex items-center space-x-8" },
                    React.createElement("span", { className: "text-[13px] pointer-events-none tracking-wide rounded font-semibold text-zinc-600 font-mono mr-4" }, "subtrace"),
                    React.createElement("a", { href: "/docs", className: "text-sm font-medium text-zinc-500/95 hover:text-zinc-500" }, "Docs"),
                    React.createElement("a", { href: "https://github.com/subtrace/subtrace", className: "text-sm font-medium text-zinc-500/95 hover:text-zinc-500" }, "GitHub"),
                    React.createElement("a", { href: "mailto:support@subtrace.dev", className: "text-sm font-medium text-zinc-500/95 hover:text-zinc-500" }, "Support"))))));
}
