import * as React from 'react';
import { AppContents } from 'AppContents';
import { AppTopBar } from 'AppTopBar';
import { AppSidebar } from 'AppSideBar';
import * as CustomHooks from 'CustomHooks';
import * as ReactRouterDom from 'react-router-dom';
export function AppMain() {
    const navigate = ReactRouterDom.useNavigate();
    const isLoggedIn = CustomHooks.useIsLoggedIn();
    if (isLoggedIn === false) {
        navigate('/login');
    }
    return (React.createElement("div", { className: "w-dvw h-dvh flex flex-col bg-gray-900" }, isLoggedIn ? (React.createElement(React.Fragment, null,
        React.createElement(AppTopBar, null),
        React.createElement("div", { className: "flex flex-row grow" },
            React.createElement(AppSidebar, null),
            React.createElement(AppContents, { className: "mt-3 grow" })))) : null));
}
