import { NumericalFilterKind } from 'NumericalFilter';
import * as Verify from 'Utils/Verify';
export function getFilterStringValues(filter) {
    if (!filter) {
        return {
            comparisonValue: '',
            lowerBound: '',
            upperBound: '',
        };
    }
    switch (filter.filterKind) {
        case NumericalFilterKind.Between:
            return {
                comparisonValue: '',
                lowerBound: filter.lowerBound.toString(),
                upperBound: filter.upperBound.toString(),
            };
        case NumericalFilterKind.Equals:
        case NumericalFilterKind.GreaterThan:
        case NumericalFilterKind.GreaterThanOrEquals:
        case NumericalFilterKind.LesserThan:
        case NumericalFilterKind.LesserThanOrEquals:
        case NumericalFilterKind.NotEquals:
            return {
                comparisonValue: filter.filterValue.toString(),
                lowerBound: '',
                upperBound: '',
            };
        default:
            Verify.isNever(filter);
    }
}
